export default function Home() {
  return (
    <main className="flex min-h-screen h-screen items-center justify-center p-24">
      <div className="relative flex place-items-center -translate-y-12 before:absolute before:h-[25rem] before:w-full sm:before:w-[37.5rem] before:-translate-x-1/2 before:rounded-full before:bg-gradient-radial before:from-white before:to-transparent before:blur-3xl before:content-[''] after:absolute after:-z-20 after:h-[12.5rem] after:w-full sm:after:w-[22.5rem] after:translate-x-1/3 after:bg-gradient-conic after:from-sky-300 after:via-blue-400 after:blur-3xl after:content-[''] before:dark:bg-gradient-to-br before:dark:from-transparent before:dark:to-blue-600 before:dark:opacity-30 after:dark:from-sky-900 after:dark:via-[#0141ff] after:dark:opacity-70 before:lg:h-[31.5rem] z-[-1]">
        <h1 className="relative text-5xl font-bold font-sans text-blue-900 dark:text-blue-900 sm:text-6xl lg:text-7xl">
          Inksmart.in
        </h1>
      </div>
    </main>
  );
}
